import React from 'react';
import { Translate } from 'react-jhipster';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faUser, faHome, faCog, faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    {/* <img src="content/images/logo-jhipster.png" alt="Logo" /> */}
    <img src="content/images/your-logo-here.jpg" alt="Logo" />
  </div>
);

export const Brand = () => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <BrandIcon />
    <span className="brand-title">
      <Translate contentKey="global.title">AlexisExport</Translate>
    </span>
    <span className="navbar-version">{VERSION.toLowerCase().startsWith('v') ? VERSION : `v${VERSION}`}</span>
  </NavbarBrand>
);

export const Home = ({ onClick }) => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center" onClick={onClick}>
      <FontAwesomeIcon icon="home" />
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const TableauDeBord = ({ onClick }) => (
  <NavItem>
    <NavLink tag={Link} to="/tableau-de-bord" className="d-flex align-items-center" onClick={onClick}>
      <FontAwesomeIcon icon={faCog} />
      <span>
    {/*      <Translate contentKey="global.menu.home">Dashborad</Translate> */}
        <div>Tableau De Bord</div>
      </span>
    </NavLink>
  </NavItem>
);

export const PiecesComptables = ({ onClick }) => (
  <NavItem>
    <NavLink tag={Link} to="/pieces-comptables" className="d-flex align-items-center" onClick={onClick}>
      <FontAwesomeIcon icon={faEnvelope} />
      <span>
  {/*      <Translate contentKey="global.menu.home">Accounting docs</Translate> */}
        <div>Pièces Comptables</div>
      </span>
    </NavLink>
  </NavItem>
);
