import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Rebut from './rebut';
import RebutDetail from './rebut-detail';
import RebutUpdate from './rebut-update';
import RebutDeleteDialog from './rebut-delete-dialog';

const RebutRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Rebut />} />
    <Route path="new" element={<RebutUpdate />} />
    <Route path=":id">
      <Route index element={<RebutDetail />} />
      <Route path="edit" element={<RebutUpdate />} />
      <Route path="delete" element={<RebutDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RebutRoutes;
