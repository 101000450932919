import dayjs from 'dayjs';

export interface IProduit {
  id?: number;
  name?: string;
  provenance?: string;
  poids?: number;
  priceDeVente?: number;
  prixInitial?: number;
  sachetDeProtection?: number;
  dateDExpedition?: dayjs.Dayjs;
  dateDeReception?: dayjs.Dayjs;
  lienVersFichierPhoto?: string;
  datePersistence?: dayjs.Dayjs;
  dateUpdate?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<IProduit> = {};
