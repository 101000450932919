import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IProduit } from 'app/shared/model/produit.model';
import { getEntity, updateEntity, createEntity, reset } from './produit.reducer';

export const ProduitUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const produitEntity = useAppSelector(state => state.produit.entity);
  const loading = useAppSelector(state => state.produit.loading);
  const updating = useAppSelector(state => state.produit.updating);
  const updateSuccess = useAppSelector(state => state.produit.updateSuccess);

  const handleClose = () => {
    navigate('/produit' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.poids !== undefined && typeof values.poids !== 'number') {
      values.poids = Number(values.poids);
    }
    if (values.priceDeVente !== undefined && typeof values.priceDeVente !== 'number') {
      values.priceDeVente = Number(values.priceDeVente);
    }
    if (values.prixInitial !== undefined && typeof values.prixInitial !== 'number') {
      values.prixInitial = Number(values.prixInitial);
    }
    if (values.sachetDeProtection !== undefined && typeof values.sachetDeProtection !== 'number') {
      values.sachetDeProtection = Number(values.sachetDeProtection);
    }
    values.dateDExpedition = convertDateTimeToServer(values.dateDExpedition);
    values.dateDeReception = convertDateTimeToServer(values.dateDeReception);
    values.datePersistence = convertDateTimeToServer(values.datePersistence);
    values.dateUpdate = convertDateTimeToServer(values.dateUpdate);

    const entity = {
      ...produitEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          dateDExpedition: displayDefaultDateTime(),
          dateDeReception: displayDefaultDateTime(),
          datePersistence: displayDefaultDateTime(),
          dateUpdate: displayDefaultDateTime(),
        }
      : {
          ...produitEntity,
          dateDExpedition: convertDateTimeFromServer(produitEntity.dateDExpedition),
          dateDeReception: convertDateTimeFromServer(produitEntity.dateDeReception),
          datePersistence: convertDateTimeFromServer(produitEntity.datePersistence),
          dateUpdate: convertDateTimeFromServer(produitEntity.dateUpdate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alexisExportApp.produit.home.createOrEditLabel" data-cy="ProduitCreateUpdateHeading">
            <Translate contentKey="alexisExportApp.produit.home.createOrEditLabel">Create or edit a Produit</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="produit-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alexisExportApp.produit.name')}
                id="produit-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.produit.provenance')}
                id="produit-provenance"
                name="provenance"
                data-cy="provenance"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.produit.poids')}
                id="produit-poids"
                name="poids"
                data-cy="poids"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.produit.priceDeVente')}
                id="produit-priceDeVente"
                name="priceDeVente"
                data-cy="priceDeVente"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.produit.prixInitial')}
                id="produit-prixInitial"
                name="prixInitial"
                data-cy="prixInitial"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.produit.sachetDeProtection')}
                id="produit-sachetDeProtection"
                name="sachetDeProtection"
                data-cy="sachetDeProtection"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.produit.dateDExpedition')}
                id="produit-dateDExpedition"
                name="dateDExpedition"
                data-cy="dateDExpedition"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.produit.dateDeReception')}
                id="produit-dateDeReception"
                name="dateDeReception"
                data-cy="dateDeReception"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.produit.lienVersFichierPhoto')}
                id="produit-lienVersFichierPhoto"
                name="lienVersFichierPhoto"
                data-cy="lienVersFichierPhoto"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.produit.datePersistence')}
                id="produit-datePersistence"
                name="datePersistence"
                data-cy="datePersistence"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.produit.dateUpdate')}
                id="produit-dateUpdate"
                name="dateUpdate"
                data-cy="dateUpdate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/produit" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProduitUpdate;
