// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles pour PiecesComptables */
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.comment-header {
  width: 100%;
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
}

.chart-container {
  flex: 1 1 100%;
  min-width: 300px;
  background-color: #e9ecef;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/pieces-comptables/pieces-comptables.scss"],"names":[],"mappings":"AAAA,iCAAA;AACA;EACE,aAAA;EACA,eAAA;EACA,6BAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,yBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;AACF","sourcesContent":["/* Styles pour PiecesComptables */\n.container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  padding: 20px;\n}\n\n.comment-header {\n  width: 100%;\n  background-color: #f8d7da;\n  color: #721c24;\n  padding: 10px;\n  margin-bottom: 20px;\n  border-radius: 5px;\n  text-align: center;\n}\n\n.chart-container {\n  flex: 1 1 100%;\n  min-width: 300px;\n  background-color: #e9ecef;\n  padding: 20px;\n  border-radius: 5px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
