import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './devis.reducer';

export const DevisDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const devisEntity = useAppSelector(state => state.devis.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="devisDetailsHeading">
          <Translate contentKey="alexisExportApp.devis.detail.title">Devis</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{devisEntity.id}</dd>
          <dt>
            <span id="montantTotal">
              <Translate contentKey="alexisExportApp.devis.montantTotal">Montant Total</Translate>
            </span>
          </dt>
          <dd>{devisEntity.montantTotal}</dd>
          <dt>
            <span id="prixDeTransport">
              <Translate contentKey="alexisExportApp.devis.prixDeTransport">Prix De Transport</Translate>
            </span>
          </dt>
          <dd>{devisEntity.prixDeTransport}</dd>
          <dt>
            <span id="isPayedIsFacture">
              <Translate contentKey="alexisExportApp.devis.isPayedIsFacture">Is Payed Is Facture</Translate>
            </span>
          </dt>
          <dd>{devisEntity.isPayedIsFacture ? 'true' : 'false'}</dd>
          <dt>
            <span id="lienVersDevisPdf">
              <Translate contentKey="alexisExportApp.devis.lienVersDevisPdf">Lien Vers Devis Pdf</Translate>
            </span>
          </dt>
          <dd>{devisEntity.lienVersDevisPdf}</dd>
          <dt>
            <span id="datePersistence">
              <Translate contentKey="alexisExportApp.devis.datePersistence">Date Persistence</Translate>
            </span>
          </dt>
          <dd>
            {devisEntity.datePersistence ? <TextFormat value={devisEntity.datePersistence} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="dateUpdate">
              <Translate contentKey="alexisExportApp.devis.dateUpdate">Date Update</Translate>
            </span>
          </dt>
          <dd>{devisEntity.dateUpdate ? <TextFormat value={devisEntity.dateUpdate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alexisExportApp.devis.client">Client</Translate>
          </dt>
          <dd>{devisEntity.client ? devisEntity.client.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/devis" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/devis/${devisEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DevisDetail;
