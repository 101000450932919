import React from 'react';
import './pieces-comptables.scss'; // Import du fichier CSS externe

const PiecesComptables: = () => {
  return (
    <div className="container">
      {/* Comment Header */}
      <div className="comment-header">
        PAGE EN COURS DE CONSTRUCTION : Ces valeurs sont pour l'instant fake en attendant les données réelles en provenance de la base de données. Nous avons juste préparé ici les éléments nécessaires prêts à être reliés aux vraies données.
      </div>

      <div className="chart-container">
        <h3>Gestion de Devis et de Factures</h3>
        {/* Contenu temporaire pour rendre le bloc visible */}
        <p>Contenu temporaire ici. Ajoutez des graphiques ou d'autres éléments ici.</p>
      </div>
    </div>
  );
};

export default PiecesComptables;
