import React from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the necessary components and the datalabels plugin
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement, ChartDataLabels);

const TableauDeBord: React.FC = () => {
  // Fake data for the line chart (Chiffre d'affaire)
  const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Chiffre d Affaire',
        data: [12000, 15000, 13000, 17000, 21000, 19000, 23000],
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
      },
    ],
  };

  // Fake data for the pie chart (Répartition des ventes des produits)
  const pieData = {
    labels: ['Produit A', 'Produit B', 'Produit C', 'Produit D', 'Produit E', 'Produit F', 'Produit G', 'Produit H'],
    datasets: [
      {
        data: [300, 50, 100, 40, 60, 30, 20, 90],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56', '#C9CBCF'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56', '#C9CBCF'],
      },
    ],
  };

  const pieOptions = {
    plugins: {
      datalabels: {
        color: '#fff',
        font: {
          size: 14,
        },
        formatter: (value: number) => `${value}`, // Display the actual value
      },
    },
  };

  return (
    <div style={styles.container}>
      {/* Comment Header */}
      <div style={styles.commentHeader}>
        <strong>PAGE EN COURS DE CONSTRUCTION :</strong> Ces valeurs sont pour l'instant fake en attendant les données réelles en provenance
        de la base de données. Nous avons juste préparé ici les éléments nécessaires prêts à être reliés aux vraies données.
      </div>
      <div style={styles.chartContainer}>
        <h3>Évolution du Chiffre d'Affaire</h3>
        <Line data={lineData} />
      </div>
      <div style={styles.chartContainer}>
        <h3>Répartition des Ventes des Produits</h3>
        <Pie data={pieData} options={pieOptions} />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    padding: '20px',
  } as React.CSSProperties,
  chartContainer: {
    flex: '1 1 45%', // Makes each chart take 45% of the width
    minWidth: '300px', // Minimum width to maintain readability
    marginBottom: '20px',
  } as React.CSSProperties,
  commentHeader: {
    marginBottom: '20px',
    padding: '10px',
    backgroundColor: '#ffcccc', // Light red background to make it noticeable
    borderRadius: '5px',
    color: '#333',
    fontSize: '14px',
    textAlign: 'center',
  } as React.CSSProperties,
};

export default TableauDeBord;
