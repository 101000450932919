import produit from 'app/entities/produit/produit.reducer';
import client from 'app/entities/client/client.reducer';
import photo from 'app/entities/photo/photo.reducer';
import devis from 'app/entities/devis/devis.reducer';
import ligneProduit from 'app/entities/ligne-produit/ligne-produit.reducer';
import partenaire from 'app/entities/partenaire/partenaire.reducer';
import rebut from 'app/entities/rebut/rebut.reducer';
import conteneur from 'app/entities/conteneur/conteneur.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  produit,
  client,
  photo,
  devis,
  ligneProduit,
  partenaire,
  rebut,
  conteneur,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
