import dayjs from 'dayjs';
import { IProduit } from 'app/shared/model/produit.model';

export interface IRebut {
  id?: number;
  datePersistence?: dayjs.Dayjs;
  dateUpdate?: dayjs.Dayjs | null;
  produit?: IProduit | null;
}

export const defaultValue: Readonly<IRebut> = {};
