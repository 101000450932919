import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IClient } from 'app/shared/model/client.model';
import { getEntities as getClients } from 'app/entities/client/client.reducer';
import { IDevis } from 'app/shared/model/devis.model';
import { getEntity, updateEntity, createEntity, reset } from './devis.reducer';

export const DevisUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const clients = useAppSelector(state => state.client.entities);
  const devisEntity = useAppSelector(state => state.devis.entity);
  const loading = useAppSelector(state => state.devis.loading);
  const updating = useAppSelector(state => state.devis.updating);
  const updateSuccess = useAppSelector(state => state.devis.updateSuccess);

  const handleClose = () => {
    navigate('/devis' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getClients({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.montantTotal !== undefined && typeof values.montantTotal !== 'number') {
      values.montantTotal = Number(values.montantTotal);
    }
    if (values.prixDeTransport !== undefined && typeof values.prixDeTransport !== 'number') {
      values.prixDeTransport = Number(values.prixDeTransport);
    }
    values.datePersistence = convertDateTimeToServer(values.datePersistence);
    values.dateUpdate = convertDateTimeToServer(values.dateUpdate);

    const entity = {
      ...devisEntity,
      ...values,
      client: clients.find(it => it.id.toString() === values.client?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          datePersistence: displayDefaultDateTime(),
          dateUpdate: displayDefaultDateTime(),
        }
      : {
          ...devisEntity,
          datePersistence: convertDateTimeFromServer(devisEntity.datePersistence),
          dateUpdate: convertDateTimeFromServer(devisEntity.dateUpdate),
          client: devisEntity?.client?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alexisExportApp.devis.home.createOrEditLabel" data-cy="DevisCreateUpdateHeading">
            <Translate contentKey="alexisExportApp.devis.home.createOrEditLabel">Create or edit a Devis</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="devis-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alexisExportApp.devis.montantTotal')}
                id="devis-montantTotal"
                name="montantTotal"
                data-cy="montantTotal"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.devis.prixDeTransport')}
                id="devis-prixDeTransport"
                name="prixDeTransport"
                data-cy="prixDeTransport"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.devis.isPayedIsFacture')}
                id="devis-isPayedIsFacture"
                name="isPayedIsFacture"
                data-cy="isPayedIsFacture"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alexisExportApp.devis.lienVersDevisPdf')}
                id="devis-lienVersDevisPdf"
                name="lienVersDevisPdf"
                data-cy="lienVersDevisPdf"
                type="text"
              />
              <ValidatedField
                label={translate('alexisExportApp.devis.datePersistence')}
                id="devis-datePersistence"
                name="datePersistence"
                data-cy="datePersistence"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alexisExportApp.devis.dateUpdate')}
                id="devis-dateUpdate"
                name="dateUpdate"
                data-cy="dateUpdate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="devis-client"
                name="client"
                data-cy="client"
                label={translate('alexisExportApp.devis.client')}
                type="select"
              >
                <option value="" key="0" />
                {clients
                  ? clients.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/devis" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DevisUpdate;
