import React from 'react';
import { Translate } from 'react-jhipster';
import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = ({ onClick }) => (
  <>
    <MenuItem icon="asterisk" to="/produit" onClick={onClick}>
      <Translate contentKey="global.menu.entities.produit" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/client" onClick={onClick}>
      <Translate contentKey="global.menu.entities.client" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/photo" onClick={onClick}>
      <Translate contentKey="global.menu.entities.photo" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/devis" onClick={onClick}>
      <Translate contentKey="global.menu.entities.devis" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/ligne-produit" onClick={onClick}>
      <Translate contentKey="global.menu.entities.ligneProduit" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/partenaire" onClick={onClick}>
      <Translate contentKey="global.menu.entities.partenaire" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/rebut" onClick={onClick}>
      <Translate contentKey="global.menu.entities.rebut" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conteneur" onClick={onClick}>
      <Translate contentKey="global.menu.entities.conteneur" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </>
);

export default EntitiesMenu;
