import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Produit from './produit';
import Client from './client';
import Photo from './photo';
import Devis from './devis';
import LigneProduit from './ligne-produit';
import Partenaire from './partenaire';
import Rebut from './rebut';
import Conteneur from './conteneur';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="produit/*" element={<Produit />} />
        <Route path="client/*" element={<Client />} />
        <Route path="photo/*" element={<Photo />} />
        <Route path="devis/*" element={<Devis />} />
        <Route path="ligne-produit/*" element={<LigneProduit />} />
        <Route path="partenaire/*" element={<Partenaire />} />
        <Route path="rebut/*" element={<Rebut />} />
        <Route path="conteneur/*" element={<Conteneur />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
